<template>
  <div class="sidebar">
    <el-menu
      router
      unique-opened
      class="menu"
      :class="{ collapse }"
      :collapse-transition="false"
      :default-active="$route.path"
    >
      <template
        v-for="item in menuData"
      >
        <el-submenu
          v-if="item.children"
          :key="item.index"
          :index="item.index"
          :class="item.classname"
          class="submenu"
        >
          <template slot="title">
            <img
              :src="item.icon"
              :alt="item.label"
              class="icon"
            >
            <span class="label">{{ item.label }}</span>
          </template>
          <el-menu-item
            v-for="child in item.children"
            :key="child.index"
            :index="child.index"
            class="menu-item"
            :disabled="child.isComingSoon"
          >
            {{ collapse ? child.abbreviation : child.label }}
            <img
              v-if="child.hotIcon"
              src="@/assets/images/icons/hot-fire.svg"
              alt="hot service"
              class="hot-icon"
            >
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          v-else
          :key="item.index"
          :index="item.isComingSoon? '': item.index"
          :class="item.classname"
          class="submenu"
          :disabled="item.isComingSoon"
        >
          <img
            :src="item.icon"
            :alt="item.label"
            class="icon"
          >
          <span class="label">{{ item.label }}</span>
        </el-menu-item>
      </template>
    </el-menu>
    <button
      class="toggle"
      :class="{ collapse }"
      @click="toggleClick"
    >
      <i class="el-icon-arrow-left icon" />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

const MOBILE_WIDTH = 414;

export default {
  name: 'Sidebar',
  props: {
    route: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      collapse: 'isSidebarCollapsed',
    }),
    menuData() {
      const parentPath = this.route.path;
      return this.route.children.filter(({ meta: { hideInNav } }) => !hideInNav).map((route) => ({
        label: this.$t(`button['${route.meta.navName}']`),
        index: `${parentPath}/${route.path}`,
        icon: route.meta.iconCompact,
        classname: route.path,
        isComingSoon: route.meta.isComingSoon,

        children: route.children && route.children.filter(({ meta: { hideInNav } }) => !hideInNav).slice(0, -1)
          .map((child) => ({
            label: this.$t(`button['${child.meta.navName}']`),
            abbreviation: child.meta.abbreviation,
            index: `${parentPath}/${route.path}/${child.path}`,
            hotIcon: child.meta.hotIcon,
            isComingSoon: child.meta.isComingSoon,
          })),
      }));
    },
  },
  mounted() {
    if (window.innerWidth < MOBILE_WIDTH) {
      this.setSidebarCollapsed(true);
    }
  },
  methods: {
    ...mapMutations({
      setSidebarCollapsed: 'SET_SIDEBAR_COLLAPSED',
    }),
    toggleClick() {
      this.setSidebarCollapsed(!this.collapse);
    },
  },
};
</script>

<style lang="scss" scoped>
  $colors: (
    mt: #416FAD,
    nlp: #FC9C0D,
    vca: #521FD1,
    aiu: #4791FF,
    asa: #14D4B2,
    kg: #FF421D,
    med: #FC5BA8,
  );
  $toggle-bg: #D9CAFF;
  $toggle-color: #521FD1;

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 0.37rem;
    border-right: $border-sidebar;
    @include tablet-and-less {
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding-top: 0;
    }

    /deep/ .el-menu-item,
    /deep/ .el-submenu__title {
      height: 0.56rem;
      line-height: 0.56rem;
    }

    .menu {
      max-width: $services-sidebar-width;
      border-right: none;

      &.collapse {
        width: $services-sidebar-collapsed-width;
        overflow: hidden;

        & .submenu {
          & .menu-item {
            padding-left: 0.3rem !important; // Overwriting inline style from element-ui

            &:before {
              left: 0.08rem
            }
          }

          & /deep/ .el-submenu__title {
            min-width: unset;

            & .label {
              display: none;
            }
          }

          & > .label {
            display: none;
          }
        }

        &.hot-icon {
          display: none;
        }
      }

      /deep/ .submenu.el-menu-item.is-active {
        color: $text-primary;
      }

      .submenu {
        margin-bottom: 0.16rem;

        &.el-menu-item,
        /deep/ .el-submenu__title {
          position: relative;
          display: flex;
          align-items: center;
          min-height: 0.6rem;
          padding: 0.11rem 0.2rem !important; // Overwriting inline style from element-ui
          white-space: initial;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: -1px;
            bottom: 0;

            width: 3px;
            height: 100%;
          }
        }

        /deep/ .el-submenu__title {
          min-width: $services-sidebar-width;
        }

        .icon {
          height: 0.38rem;
          width: 0.38rem;
          margin-right: 0.13rem;
        }

        .label {
          opacity: 0.6;
          font-weight: 600;
          font-size: 0.14rem;
          line-height: 0.19rem;
        }

        &.is-active .label {
          opacity: 1;
        }

        /deep/ .el-submenu__icon-arrow {
          display: none;
        }

        .menu-item {
          position: relative;
          height: 0.42rem;
          padding-right: 0.14rem;
          padding-left: 0.4rem !important; // Overwriting inline style from element-ui
          line-height: 0.42rem;
          font-size: 0.12rem;
          color: $text-primary;
          min-width: $services-sidebar-width;

          &:hover,
          &:focus {
            background: white;
            box-shadow: 0 0.04rem 0.04rem rgba(234, 239, 251, 0.37);
          }

          &:hover {
            transform: scale(1.01);
          }

          &.is-active {
            font-weight: 600;
          }

          &:before {
            position: absolute;
            content: 'east';
            left: 0.14rem;
            color: $text-dimmed;
            font-family: 'Material Icons', sans-serif;
            text-transform: none;
          }

          .hot-icon {
            height: 0.21rem;
            position: absolute;
            top: 0.1rem;
            right: 0.2rem;
          }

          &.is-disabled {
            opacity: .4;
          }
        }

        &.is-disabled {
          opacity: 1;
        }

        @each $name, $color in $colors {
          &.#{$name} {
            &.is-active /deep/ .el-submenu__title,
            &.is-active.el-menu-item {
              background: linear-gradient(90deg, rgba($color, 0.1) 1.87%, rgba(white, 0) 132.92%);

              &:after {
                background: $color;
              }
            }

            .menu-item.is-active {
              color: $color;

              &:before {
                color: $color;
              }
            }
          }
        }
      }
    }

    .toggle {
      display: flex;
      justify-content: flex-end;
      height: 0.6rem;
      width: 100%;
      color: $toggle-color;
      border: none;
      background: linear-gradient(90deg, rgba($toggle-bg, 0.37) -27.11%, rgba(white, 0) 97.39%);;
      font-size: 0.18rem;
      padding: 0.2rem;
      @include tablet-and-less {
        margin-bottom: 0.3rem;
      }

      &.collapse {
        .icon {
          margin: 0 auto;
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
