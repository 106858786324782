<template>
  <div id="ai-services">
    <div v-if="!$route.meta.hideSidebar">
      <keep-alive>
        <sidebar :route="AIServices" />
      </keep-alive>
    </div>
    <div
      id="ai-services_view-container"
      :class=" { 'with-sidebar': !$route.meta.hideSidebar, 'sidebar-collapsed': isSidebarCollapsed }"
    >
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/pages/AIServices/components/Sidebar/Index.vue';
import AIServices from '@/router/AIServices/index';
import { mapGetters } from 'vuex';

export default {
  components: { Sidebar },
  data: () => ({
    AIServices,
  }),
  computed: {
    ...mapGetters(['isSidebarCollapsed']),
  },
};
</script>

<style lang="scss" scoped>
  #ai-services {
    display: flex;

    #ai-services_view-container {
      width: 100vw;

      &.with-sidebar {
        width: calc(100vw - #{$services-sidebar-width});

        &.sidebar-collapsed {
          width: calc(100vw - #{$services-sidebar-collapsed-width});
        }
      }

      /deep/ .ai-services_introduction {
        .banner {
          position: relative;
          width: 100%;
          padding-bottom: 45.5%;
          .banner-content {
            position: absolute;
            align-items: flex-start;
            top: 50%;
            left: 6%;
            transform: translateY(-50%);
            width: 88%;
            height: 60%;
            font-family: Montserrat, sans-serif;
            color: #FFF;
            background-size: contain;
            h1 {
              position: relative;
              font-weight: bold;
              font-size: 0.42rem;
              line-height: 0.51rem;
              text-transform: uppercase;
              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: -0.5em;
                width: 5em;
                height: 0.04rem;
              }
            }
            p {
              margin-top: 1em;
              max-width: 46%;
              font-size: 0.16rem;
              line-height: 1.5em;
            }
            .login {
              width: 11.5em;
              height: 0.6rem;
              margin-top: 2em;
              font-size: 0.18rem;
              color: #FFF;
              border:none;
            }
          }
        }
      }
    }
  }
</style>
